<template>
  <div id="app" class="overflow-x-hidden">
    <router-view />
  </div>
</template>

<script>
</script>

<style></style>
