import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'App',
    component: () => import('../views/AppView.vue'),
  },
  {
    path: '/polityka-prywatnosci',
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/regulamin',
    name: 'TermsOfService',
    component: () => import('../views/TermsOfService.vue'),
  },
  {
    path: '/zaufani-partnerzy',
    name: 'TrustedPartners',
    component: () => import('../views/TrustedPartners.vue'),
  },
  {
    path: '/:catchAll(.*)', // using Vue Router 4's catch-all syntax
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
